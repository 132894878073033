/* 
 * @Author: 曹俊杰 
 * @Date: 2020-08-28 11:15:29
 * @Module: 新增主播
 */
 <template>
  <el-card style="padding-right:160px" class="anchor-form-style">
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      label-width="80px"
      :size="'mini'"
      label-position="top"
    >
      <el-row :gutter="90">
        <el-col :span="12">
          <el-form-item
            label="探探号："
            prop="tick_account"
            :error="errorMessage?errorMessage.tick_account:''"
          >
            <el-input v-model="form.tick_account" placeholder="请输入探探号"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12" v-role="['administrator']">
          <el-form-item
            label="经纪人："
            prop="broker_id"
            :error="errorMessage?errorMessage.broker_id:''"
          >
            <brokerInviter @change="id=>form.broker_id = id" placeholder="请选择经纪人" class="width100"></brokerInviter>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="公会：" prop="mcn_id" :error="errorMessage?errorMessage.mcn_id:''">
            <mcn
              class="width100"
              :filterable="false"
              @change="id=>form.mcn_id=id"
              placeholder="请选择公会"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="真实姓名：" prop="name" :error="errorMessage?errorMessage.name:''">
            <el-input v-model="form.name" placeholder="请输入真实姓名">
              <el-tooltip effect="dark" placement="top-start" slot="suffix" content="探探实名认证姓名">
                <div style="cursor: pointer;line-height:40px;">
                  <span class="icon iconfont icon-wenhao" style="color:#60C3F7"></span>
                </div>
              </el-tooltip>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="主播昵称"
                        prop="tick_name"
                        :error="errorMessage?errorMessage.tick_name:''">
            <el-input v-model="form.tick_name"
                      placeholder="请输入真主播昵称">
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="电话："
                        prop="phone"
                        :error="errorMessage?errorMessage.phone:''">
            <el-input v-model="form.phone"
                      placeholder="请输入电话">
              <el-tooltip effect="dark"
                          placement="top-start"
                          slot="suffix"
                          content="探探绑定的手机号">
                <div style="cursor: pointer;line-height:40px;">
                  <span class="icon iconfont icon-wenhao" style="color:#60C3F7"></span>
                </div>
              </el-tooltip>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="分成比："
            prop="separate_ratio"
            :error="errorMessage?errorMessage.separate_ratio:''"
          >
            <pushMoney
              class="width100"
              :filterable="false"
              @change="id=>form.separate_ratio=id"
              placeholder="请选择分成比"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="邀约状态："
                        prop="invite_state"
                        :error="errorMessage?errorMessage.invite_state:''">
            <inviteState @change="id=>form.invite_state = id"
                         :filterable="false"
                         placeholder="请选择邀约状态"
                         class="width100"></inviteState>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="90">
        <el-col :span="24">
          <el-form-item
            label="备注："
            prop="description"
            :error="errorMessage?errorMessage.description:''"
          >
            <el-input
              v-model="form.description"
              type="textarea"
              :autosize="{ minRows: 3, maxRows: 5}"
              placeholder="请输入备注"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-button type="primary" @click="submit">确定</el-button>
      </el-row>
    </el-form>
    <customModal
      :visible="addAnchorTips!==null"
      @handleOk="closeAddAnchorTips"
      :showCancelBtn="false"
    >
      <div style="line-height: 30px;padding:0 20px">{{content}}</div>
    </customModal>
  </el-card>
</template>
 <script>
import brokerInviter from "components/formComponent/brokerInviter";
import mcn from "components/formComponent/mcn";
import pushMoney from "components/formComponent/pushMoney";
import inviteState from "components/formComponent/inviteState";
export default {
  data() {
    return {
      form: {
        tick_account: "", //探探号
        broker_id: "", //经纪人
        mcn_id: "", //公会
        name: "", // 真实姓名
        phone: "", //电话
        separate_ratio: "", //分成比
        invite_state: "", //邀约状态
        description: "", //备注
        tick_name:""  // 主播昵称
      },
      rules: {
        tick_account: [
          { required: true, message: "请输入探探号", trigger: "blur" },
        ],
        broker_id: [
          { required: true, message: "请选择经纪人", trigger: "blur" },
        ],
        mcn_id: [{ required: true, message: "请选择公会", trigger: "blur" }],
        name: [{ required: true, message: "请输入真实姓名", trigger: "blur" }],
        phone: [
          { required: true, message: "请输入电话", trigger: "blur" },
          { min: 11, max: 11, message: "请输入11位手机号码", trigger: "blur" },
        ],
        separate_ratio: [
          { required: true, message: "请选择分成比", trigger: "blur" },
        ],
        invite_state: [
          { required: true, message: "请选择邀约状态", trigger: "blur" },
        ],
        tick_name:[
           { required: true, message: "请输入主播昵称", trigger: "blur" },
        ]
      },
      errorMessage: null,
      reqLoading: false,
      addAnchorTips:true,
      content:"非欺诈招募保证（以直播平台官方头像或官方名义招募主播等行为，均属于欺诈招募！如招募方式不规范，请立即停止录入）",
    };
  },
  created() {
    this.$store.commit("layouts/setPathList", [
      { name: "主播列表", path: "/anchor/anchorList" },
      { name: "新增主播" },
    ]);
    this.$store.commit("layouts/changeBreadcrumb", true);
    // setTimeout(() => {
    //   this.$alert(
    //     "非欺诈招募保证（以直播平台官方头像或官方名义招募主播等行为，均属于欺诈招募！如招募方式不规范，请立即停止录入）",
    //     "",
    //     {
    //       confirmButtonText: "确定",
    //     }
    //   );
    // }, 500);
    if (!this.role(["administrator"])) {
      this.form.broker_id = this.$store.state.user.userInfo.user_id;
    }
  },
  beforeDestroy() {
    this.$store.commit("layouts/changeBreadcrumb", false);
  },
  components: {
    brokerInviter,
    mcn,
    pushMoney,
    inviteState,
  },
  mounted() {},
  methods: {
    closeAddAnchorTips(){
      this.addAnchorTips = null
    },
    submit() {
      this.errorMessage = null;
      this.$refs.form.validate((valid) => {
        if (valid) {
          this._reqAnchorCreate();
        } else {
          return false;
        }
      });
    },
    // 请求添加主播
    async _reqAnchorCreate() {
      this.reqLoading = true;
      const { form } = this;
      const { status_code, message } = await this.$api.anchorCreate({
        ...form,
      });
      if (status_code === 200) {
        this.$router.go(-1);
        this.$message({
          message: "添加成功",
          type: "success",
        });
      } else if (status_code === 422) {
        this.errorMessage = message;
      } else {
        this.$message({
          message,
          type: "warning",
        });
      }
      this.reqLoading = false;
    },
  },
};
</script>
 <style lang="less" scoped>
.width100 {
  width: 100%;
}
.anchor-form-style /deep/ .el-input__inner {
  height: 40px;
}
.anchor-form-style /deep/ .el-form-item {
  margin-bottom: 40px;
}
</style>